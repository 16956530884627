import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout/Layout';
import { withTrans } from '../i18n/withTrans';
import { Link } from 'gatsby';
import { Card } from '@material-ui/core';
import { ClockIcon } from '../assets/customIcons';
import Favicon from '../assets/images/favicon-manatour-16x16.png';

const IndexPage = ({ t }) => {
  const goToReservationPage = () => {
    const isCookiesAccepted = localStorage.getItem('isCookiesAccepted');
    localStorage.clear();
    localStorage.setItem('isCookiesAccepted', isCookiesAccepted);
  };
  return (
    <Layout pageName="home">
      <Helmet>
        <title>{t('helmet.landing')}</title>
        <link
          rel="icon"
          type="image/png"
          href={Favicon}
          sizes="16x16"
          className="favicon"
        />
      </Helmet>
      <h1 className="landing-title">
        <span className="h1-before">{t('landingPage.title')}</span>
        {t('landingPage.title2')}
        <strong>{t('landingPage.title3')}</strong>
      </h1>
      <button
        onClick={() => goToReservationPage()}
        className="primary landing-book-btn"
      >
        {/* <Link to={'https://my.weezevent.com/visitez-lopera-garnier-1'}> */}
        <Link to={'/reservation'}>{t('landingPage.buttons.book')}</Link>
      </button>
      <h2 className="landing-descr">{t('landingPage.description')}</h2>
      <Card className="landing-infocard">
        <div>
          <p>{t('landingPage.infocard.tour')}</p>
          <p>
            <span style={{ fontSize: 16, width: 60 }}>
              {t('landingPage.infocard.from')}
            </span>
            <ClockIcon />
          </p>
        </div>
        <div>
          <div>
            <p style={{ textTransform: 'uppercase' }}>
              {t('landingPage.infocard.visitor.adult')}
            </p>
            <p>{t('landingPage.infocard.visitor.full_price')}</p>
          </div>
          <div>
            <p style={{ textTransform: 'uppercase' }}>
              {t('landingPage.infocard.visitor.youth')}
            </p>
            <p>{t('landingPage.infocard.visitor.reduced_price')}</p>
          </div>
          <div>
            <p style={{ textTransform: 'uppercase' }}>
              {t('landingPage.infocard.visitor.child')}
            </p>
            <p>{t('landingPage.infocard.visitor.child_price')}</p>
          </div>
          <div>
            <p style={{ textTransform: 'uppercase' }}>
              {t('landingPage.infocard.visitor.free')}
            </p>
            <p>{t('landingPage.infocard.visitor.free_price')}</p>
          </div>
        </div>
        <div>
          <div>
            <p>
              {t('landingPage.infocard.evening-start')}
              <span>{t('landingPage.infocard.evening-time')}</span>
            </p>
          </div>
          <div>
            <p style={{ textTransform: 'uppercase' }}>
              {t('landingPage.infocard.evening-tour')}
            </p>
            <p>{t('landingPage.infocard.evening-price')}</p>
          </div>
        </div>
      </Card>
    </Layout>
  );
};

export default withTrans(IndexPage);
